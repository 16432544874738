import React, { useState, useEffect } from 'react';
import "../../../Shared/css/style.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Validation } from '../../../../Helpers/Validation';
import { Message } from '../../../../Helpers/Message';
import { UserService } from '../../../../Services/User/UserService';
import FormButton from '../../../Shared/Layout/Custom/Button/FormButton';
import { AuthService } from '../../../../Services/Auth/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormInputDiv from '../../../Shared/Layout/Custom/FormInputDiv';
import LoginImg from '../../../Shared/Images/login-img.png';
import { ImageUrl } from "../../../../Helpers/ImageUrl";
import { Environment } from '../../../../Environment/Settings';
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import './ForgotPassword.css'
const ForgotPassword = () => {
  useEffect(() => {
    AuthService.Logout();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [metaTag, setMetaTag] = useState('');
  const [title, setTitle] = useState('');
  const [bootstrapModal, setBootstrapModal] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "all" });
  let navigate = useNavigate();
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  useEffect(() => {
    setMetaTag(Message.Description);
    setTitle(Message.Title);
  }, []);
  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    UserService.ForgotPassword(data).then(data => {
      setIsLoading(false);
      if (data.isValidated) {
        toast.success(data.message, {
        });
      }
      else if (!data.isValidated) {

        setBootstrapModal(true);
        // toast.error(data.message, {
        // });
      }

    });
  })
  async function makeRequest() {

    await delay(2000);
    navigate('/');
  }
  function closeClick() {
    setBootstrapModal(false);

  }
  function redirecttohome() {
    window.location.href = Environment.PortalLink;
  }
  return (
    <div className='container-fluid container-middle-align login-page forget-page'>

      <Modal
        size="lg"
        show={bootstrapModal}

        onHide={closeClick}
        aria-labelledby="contained-modal-title-vcenter "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Account Inactive
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please confirm your account first then we can proceed with password reset
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={closeClick}>
            Close
          </button>
          {/* <button className="btn btn-success" onClick={onSubmitCompanyContact}>
              Add
            </button> */}
        </Modal.Footer>
      </Modal>

      <Helmet>
        <title>Helm-Track</title>
        <meta name="title" content={title} />
        <meta name="description" content={metaTag} />
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-md-4 p-0 no-show">
          <div className='login-main-img'>
            <img className="img-fluid" src={LoginImg} />
          </div>
        </div>
        <div className="col-lg-8 card p-0 cardWidth">
          <div className="row login-data g-0">
            <div className="col-12">
              <div className="card-body h-100 d-flex justify-content-center align-items-center">
                <div className='full-width'>
                  <div className='text-center mb-4'>
                    <a onClick={redirecttohome} className="header-two-images cursor-pointer">
                      <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                    </a>
                  </div>
                  <h5 className="card-title login-text mb-5">Forgot Password</h5>
                  <ToastContainer closeOnClick={true} />

                  <form onSubmit={onSubmit}>
                    <FormInputDiv row={"row"} col={"col-lg-12 col-md-12 col-12"} register={register} inputClass={""} name="email"
                      error={errors.email?.message} type={'text'} requiredText={Message.RequiredField} requiredValid={Validation.ValidateEmail} placeholder={"Enter Email"} ValidMessage={Message.ValidEmail} />
                    <div className="row centerAlign mt-5 mb-5">
                      <div className="col-lg-12">
                        <div className='bottom-buttons d-flex justify-content-between align-items-center'>
                          {/* <FormButton className={'btn btn-success'} IsLoading={isLoading} ButtonText="Sign In" icon={true} /> */}
                          <FormButton className={'btn btn-success'} IsLoading={isLoading} ButtonText="Forgot Password" icon={true} />
                          <Link to="/">Back to login?</Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword