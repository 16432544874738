
import fetchIntercept from "fetch-intercept";
import { Environment } from '../Environment/Settings';
import { AuthService } from "../Services/Auth/AuthService";
const header = { "Content-Type": "application/json" };

fetchIntercept.register({
  request: function (url, config) {
    if (config.headers === undefined) {
      config.headers = {}
    }
    config.headers["auth"] = Environment.Auth;
    config.headers["token"] = AuthService.CurrentTokenValue;
    config.headers["permission"] = AuthService.CurrentPermissionValue;
    config.headers["Accept"] = "application/json";

    return [url, config];

  },

  requestError: function (error) {

    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    switch (response.status) {
      case 401:
        break;
      case 302:
        AuthService.Logout();
        window.location.reload(false);
        break;
      default:
        return response;
    }
  }
});

export const Method = {
  Post,
  Get,
  PostFormData
};
function Post(route, data) {
  const res = fetch(Environment.ApiUrl + route, { method: "POST", headers: header, body: JSON.stringify(data) }, header)
    .then((response) => {
      if (response !== undefined) {
        return response.json()
      }
    })
  return res;
}
function PostFormData(route, data) {

  const res = fetch(Environment.ApiUrl + route, { method: "POST", body: data })
    .then((response) => {

      if (response !== undefined) {
        return response.json()
      }
    })
  return res;
};

function Get(route) {
  const res = fetch(Environment.ApiUrl + route, { method: "GET", headers: header })
    .then((response) => {
      if (response !== undefined) {
        return response.json()
      }
    });
  //unregister();
  return res;
}
