
export const Message = {
    //////CompanySEO
    Port: "Port  / Terminals",
    Owner: "Ship Owners / Managers",
    Builder: "Ship builder",
    FooterOwner: "owners",
    FooterBuilder: "builders",
    FooterPort: "ports",
    //////VesselMessages////////
    VesselAdded: "Vessel has been added successfully",
    VesselUpdated: "Vessel has been updated successfully",
    VesselEngineLimit: "Vessels can have maximum of 2 engines",
    NoPropulsion: "No Propulsion",
    NameEmpty: "Please enter name",
    FirstNameEmpty: "please enter first name",
    LastNameEmpty: "please enter last name",
    EmailEmpty: "Please enter email",
    YearFoundedEmpty: "Please enter year founded",
    CallSignEmpty: "Please enter call sign",
    TypeEmpty: "Please enter type",
    BuilderEmpty: "Please enter builder",
    NumberEmpty: "Please enter engine number",
    MaximumSpeedEmpty: "Please enter maximum speed",
    HullMaterialEmpty: "Please enter hull material",
    BaleCapacityEmpty: "Please enter bale capacity",
    GrainCapacityEmpty: "Please enter grain capacity",
    AgreeMessage: "I have read and agree to the Helm-Track Terms of use and Privacy Policy.",
    //Validate Error Messages
    ValidPassword: "Password Must Contain Atleast 6 Characters, One Uppercase, one Lowercase, One Number and One Special Case Number",
    ValidEmail: "Email must be valid",
    ValidFirstName: "First name must be 3 characters long",
    ValidLastName: "Last name must be 3 characters long",
    ValidUserName: "User name must be 3 characters long",
    ValidMaximumPhoneNumber: "Number must be 15 number long",
    ValidConfirmPassword: "The passwords do not match",
    ChangePassword: "Password has been changed",
    ValidMinimumPhoneNumber: "Number must be 11 digit long",
    InvalidUserName: "Invalid username or password",
    TokenInvalid: "Token is invalid",
    InvalidUsernameOrEmail: 'Username or email not exists',
    DeletedSuccessfully: 'Deleted successfully',
    SavedSuccessfully: 'Saved successfully',
    VesselLengthLimit: "Please select length between 0 to 500",
    VesselBuildYearLimit: "Please select build year between 1900 to 2039",
    VesselTonnageLimit: "Please select tonnage between 0 to 100000",
    VesselWidthLimit: "Please select width between 0 to 100000",
    CompanySavedSuccessfully: "Company is saved successfully",
    EditedSuccessfully: 'Edited successfully',
    VesselDeletedSuccessfully: 'Vessel Deleted Successfully',
    LoggedOut: "Logged out successfully",
    //Required Error Messages
    RequiredEmail: "Email is required",
    RequiredFirstName: "First name is required",
    RequiredLastName: "Last name is required",
    RequiredUserName: "User name is required",
    RequiredName: "Name is required",
    RequiredLocation: "Location is required",
    RequiredContactTypeId: "Contact Type is required",
    RequiredAddress: "Address is required",
    RequiredCity: "City is required",
    RequiredYearFounded: "Year Founded is required",
    RequiredStreet: "Street is required",
    RequiredSICCode: "SICCode is required",
    RequiredRevenue: "Revenue is required",
    RequiredProvince: "Province is required",
    RequiredLinkedin: "Linkedin is required",
    RequiredDomain: "Domain is required",
    RequiredAdminNote: "Admin note is required",
    RequiredRank: "Rank is required",
    RequiredCellNo: "Cell number is required",
    RequiredJob: "Job is required",
    RequiredWebsite: "Website is required",
    RequiredPhoneNumber: "Phone number is required",
    RequiredPostal: "Postal number is required",
    RequiredState: "Required",
    RequiredPassword: "Required",
    RequiredCountry: "Country name is required",
    RequiredTitle: "Title is required",
    RequiredCompanyTypeId: "Industry is required",
    RequiredCountryId: "Country is required",
    RequiredField: "Required",
    AddedSuccessfully: 'Added successfully',
    /////////////////
    ///////META-Description-Generic//////////
    Description: "Vessels, companies and individuals that make the specifying and purchasing decisions for the specific types of vessels, shipbuilding and repair yards",
    Title: "Helm-Track"
}