export const ApiUrl = {
  ///////////Company///////////////
  GetCompanyList: "company/getall",
  GetComapnyDetail: "company",
  GetData: "auth/getdata",
  GoogleSignIn: "auth/googlesignin",
  GetDataCompany: "company/getdata",
  GetCompaniesByFlag: "company/byflag",

  /////////Vessel//////////////
  GetVesselList: "vessel/getall",
  VesselDetail: "vessel/getvesselbyId",
  GetVesselDetail: "vessel",
  GetVesselData: "vessel/getdata",
  GetVesselType: "vessel/getvesseltype",
  AddVessel: "vessel",
  DeleteVessel: "vessel/delete",
  GetCompanyType: "vessel/getcompanytype",
  GetAllVesselDetailInfo: "vessel/getInfo",
  GetAllVesselDetail: "vesselInfo",
  GetVesselByUserId: "vessel/getvesselbyuserid",
  GetVesselByFlag: "vessel/getbyflag",
  GetVesselByType: "vessel/getbytype",
  VesselBuilder: "auth/getcompanytype",
  //////////Profile///////////////
  GetProfile: "profile/get",
  UpdateProfile: "profile/save",
  //////auth////////////
  Signup: "auth/signup",
  Signin: "auth/signin",
  ForgotPassword: "auth/forgotpassword",
  ResetPassword: "auth/resetpassword",
  UpdatePassword: "auth/updatepassword",
  AccountConfirmation: "auth/confirmaccount",
  AccountConfirmationEmail: "auth/confirmaccountEmail",
  ValidateToken: "home/gettokenvalid",
  //////////Audience builder/////////
  GetAudienceData: "audiencebuilder/getdata",
  GetAudienceVesselData: "audiencebuilder/getvesselcharts",
  GetAudienceContactData: "audiencebuilder/getContactcharts",
  GetAudiencePieChartData: "audiencebuilder/getPiecharts",
  GetVesselFiltersData: "audiencebuilder/getvesseldata",
  GetVesselChartsFilterData: "audiencebuilder/getfilteredchartsdata",
  GetVesselFiltersData1: "audiencebuilder/getvesseldata1"
};
