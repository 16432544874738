import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserService } from '../../../../Services/User/UserService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthService } from '../../../../Services/Auth/AuthService';
import { Message } from '../../../../Helpers/Message';
import { ImageUrl } from "../../../../Helpers/ImageUrl";
import LoginImg from '../../../Shared/Images/login-img.png';
import { Environment } from '../../../../Environment/Settings';
import { Helmet } from "react-helmet";
import './ConfirmAccount.css'

const ConfirmAccount = () => {

  const { email } = useParams();
  const [alreadyConfirmed, setAlreadyConfirmed] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [metaTag, setMetaTag] = useState('');
  const [title, setTitle] = useState('');
  useEffect(() => {
    setMetaTag(Message.Description);
    setTitle(Message.Title);

    AuthService.Logout();

    UserService.ConfirmAccount({ email }).then(data => {
      if (data.message === Message.InvalidUserName) {
        setAlreadyConfirmed(true);
        setTokenValid(false);

        toast.error(data.message, {
        });
      }
      else if (data.message === Message.TokenInvalid) {
        setTokenValid(true);
        setAlreadyConfirmed(true);

        toast.error(data.message, {
        });
      }
    });
  }, [email]);
  function redirecttohome() {
    window.location.href = Environment.PortalLink;
  }
  return (
    <div className='container-fluid container-middle-align login-page'>
      <Helmet>
        <title>Helm-Track</title>
        <meta name="Title" content={title} />
        <meta name="description" content={metaTag} />
      </Helmet>
      <ToastContainer closeOnClick={true} />
      <div className="row justify-content-center">
        <div className="col-md-4 p-0 no-show">
          <div className='login-main-img'>
            <img className="img-fluid" src={LoginImg} />
          </div>
        </div>
        <div className="col-lg-8 card p-0 cardWidth">
          <div className="row login-data g-0">
            <div className="col-12">
              <div className="card-body h-100 d-flex justify-content-center align-items-center">
                <div className='full-width text-center'>
                  <div className='text-center mb-4'>
                    <a onClick={redirecttohome} className="header-two-images cursor-pointer">
                      <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                    </a>
                  </div>
                  <h5 className="card-title login-text mb-5">Account Activation</h5>
                  <div className="row ">
                    <div className="col-lg-12 col-md-12 col-12 centerAlign">

                      {!alreadyConfirmed && <div>Your account has been activated </div>}
                      {alreadyConfirmed && <div>

                        {!tokenValid && <div>Your account was already activated </div>}
                        {tokenValid && <div>Your token is expired. Contact administrator</div>}
                      </div>}

                    </div> </div>
                  <div className="row ">
                    <div className="col-lg-12 col-md-12 col-12 mt-3 centerAlign"><Link to="/">Back to login?</Link>
                    </div> </div></div>
              </div>
            </div>
          </div></div></div></div>
  )
}

export default ConfirmAccount