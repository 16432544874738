import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Views/Auth/Login/Login";
import SignUp from "./Components/Views/Auth/SignUp/SignUp";
import ForgotPassword from "./Components/Views/Auth/ForgotPassword/ForgotPassword";
import UpdatePassword from "./Components/Views/Profile/UpdatePassword/UpdatePassword";
import Error404 from "./Components/Shared/Layout/Custom/Error404";
import ResetPassword from "./Components/Views/Auth/ResetPassword/ResetPassword";
import ConfirmAccount from "./Components/Views/Auth/ConfirmAccount/ConfirmAccount";
import WithNav from "./Components/Views/Navigation/WithNav";
import WithoutNav from "./Components/Views/Navigation/WithoutNav";
import { AppUrl } from "./Helpers/AppUrl";
import Maritime from "./Components/Views/Maritime/Maritime";
import { lazy, Suspense } from "react";
import About from "./Components/Views/About/About";
// import Suppliers from "./Components/Views/Suppliers/Suppliers";
const HomeLazy = lazy(() => import("./Components/Views/Home/Home"));
const Vessellazy = lazy(() => import("./Components/Views/Vessel/Vessel"));
const Companylazy = lazy(() => import("./Components/Views/Company/Company"));
const OffShore = lazy(() =>
  import("./Components/Views/OffShoreNews/OffShoreNews")
);
const Privacy = lazy(() => import("./Components/Views/Privacy/Privacy"));
const Terms = lazy(() => import("./Components/Views/Terms/Terms"));
const ContactUs = lazy(() => import("./Components/Views/ContactUs/ContactUs"));
const CompanyDetailLazy = lazy(() =>
  import("./Components/Views/Company/ViewCompany/CompanyInfo")
);
const AudienceBuilder = lazy(() =>
  import("./Components/Views/AudienceBuilder/AudienceBuilder")
);
const VesselManagement = lazy(() =>
  import("./Components/Views/VesselManagement/VesselManagement")
);
const VesselInfoLazy = lazy(() =>
  import("./Components/Views/Vessel/ViewVessel/VesselInfo")
);
const AddVesselLazy = lazy(() =>
  import("./Components/Views/Vessel/AddVessel/AddVessel")
);
const ChangePassword = lazy(() =>
  import("./Components/Views/Profile/UpdatePassword/UpdatePassword")
);
const UpdateProfilelazy = lazy(() =>
  import("./Components/Views/Profile/UpdateProfile/UpdateProfile")
);
const Adveriselazy = lazy(() =>
  import("./Components/Views/Advertise/Advertise")
);
const PropulsionLazy = lazy(() =>
  import("./Components/Views/Propulsion/Propulsion")
);
function App() {
  return (
    <div className="App">
      <Suspense
        fallback={
          <div className="main-loader">
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <div className="loading loading04">
                <span>H</span>
                <span>E</span>
                <span>L</span>
                <span>M</span>
                <span>&nbsp;</span>
                <span>T</span>
                <span>R</span>
                <span>A</span>
                <span>C</span>
                <span>K</span>
              </div>
            </div>
          </div>
        }
      >
        <BrowserRouter>
          <Routes>
            <Route element={<WithoutNav />}>
              <Route path="/" element={<Login />} />
              <Route path={AppUrl.Login} element={<Login />} />
              <Route path="/:callback" element={<Login />} />
              <Route path={AppUrl.Signup} element={<SignUp />} />
              <Route
                path={AppUrl.ForgetPassword}
                element={<ForgotPassword />}
              />
              <Route
                path={AppUrl.UpdatePassword}
                element={<UpdatePassword />}
              />
              <Route path={AppUrl.ResetPassword} element={<ResetPassword />} />
              <Route
                path={AppUrl.ConfirmAccount}
                element={<ConfirmAccount />}
              />
            </Route>
            <Route exact path="*" element={<Error404 />} />
            <Route path={AppUrl.Advertise} element={<Adveriselazy />} />
            <Route element={<WithNav />}>
              <Route exact path="*" element={<Error404 />} />
              <Route path={AppUrl.Home} element={<HomeLazy />} />
              <Route
                path={AppUrl.ChangePassword}
                element={<ChangePassword />}
              />
              <Route path={AppUrl.Company} element={<Companylazy />} />
              <Route path={AppUrl.CompanySearch} element={<Companylazy />} />
              <Route path={AppUrl.CompanySearch1} element={<Companylazy />} />
              <Route path={AppUrl.CompanySearch2} element={<Companylazy />} />

              <Route
                path={AppUrl.VesselManagement}
                element={<VesselManagement />}
              />
              <Route
                path={AppUrl.ViewCompany}
                element={<CompanyDetailLazy />}
              />
              {/* <Route path={AppUrl.Suppliers} element={<Suppliers />} /> */}
              <Route
                path={AppUrl.AudinenceBuilder}
                element={<AudienceBuilder />}
              />
              <Route
                path={AppUrl.AudinenceBuilderSearch}
                element={<AudienceBuilder />}
              />
              <Route
                path={AppUrl.AudinenceBuilderSearch1}
                element={<AudienceBuilder />}
              />
              <Route
                path={AppUrl.AudinenceBuilderSearch2}
                element={<AudienceBuilder />}
              />
              <Route
                path={AppUrl.AudinenceBuilderSearch3}
                element={<AudienceBuilder />}
              />
              <Route path={AppUrl.Terms} element={<Terms />} />
              <Route path={AppUrl.Privacy} element={<Privacy />} />
              <Route path={AppUrl.ContactUs} element={<ContactUs />} />
              <Route path={AppUrl.About} element={<About />} />
              <Route path={AppUrl.MartimeNews} element={<Maritime />} />
              <Route path={AppUrl.RouteNews} element={<Maritime />} />
              <Route path={AppUrl.Propulsion} element={<PropulsionLazy />} />
              <Route path={AppUrl.Vessel} element={<Vessellazy />} />
              <Route path={AppUrl.VesselSearch} element={<Vessellazy />} />
              <Route path={AppUrl.VesselSearch1} element={<Vessellazy />} />
              <Route path={AppUrl.VesselSearch2} element={<Vessellazy />} />
              <Route path={AppUrl.VesselSearch3} element={<Vessellazy />} />
              <Route path={AppUrl.VesselSearch4} element={<Vessellazy />} />
              <Route path={AppUrl.VesselSearch5} element={<Vessellazy />} />
              <Route path={AppUrl.ViewVessel} element={<VesselInfoLazy />} />
              <Route path={AppUrl.OffShore} element={<OffShore />} />
              <Route path={AppUrl.AddNewVessel} element={<AddVesselLazy />} />
              {/* <Route path={AppUrl.UpdateNewVessel}element={<AddVesselLazy />} /> */}
              <Route
                path={AppUrl.UpdateProfile}
                element={<UpdateProfilelazy />}
              />
            </Route>
          </Routes>
          {/* <Footer /> */}
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
