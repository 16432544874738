import React from 'react';
import { Outlet } from 'react-router';
import { UnProtectedRoute } from '../../../Services/UnProtectedRoute';
export default function WithoutNav() {
    return (

        <UnProtectedRoute>
            <Outlet />
        </UnProtectedRoute>
    );
} 