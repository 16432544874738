import React, { useEffect, useState } from 'react'
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { UserService } from '../../../../Services/User/UserService';
import { AuthService } from '../../../../Services/Auth/AuthService';
import { Message } from '../../../../Helpers/Message';
import { Validation } from '../../../../Helpers/Validation';
import { ToastContainer, toast } from 'react-toastify';
import FormInputDiv from '../../../Shared/Layout/Custom/FormInputDiv';
import FormInputCp from '../../../Shared/Layout/Custom/FormInputCp';
import 'react-toastify/dist/ReactToastify.css';
import FormButton from '../../../Shared/Layout/Custom/Button/FormButton';
import { AppUrl } from '../../../../Helpers/AppUrl';
import { ImageUrl } from "../../../../Helpers/ImageUrl";
import LoginImg from '../../../Shared/Images/login-img.png';
import { Environment } from '../../../../Environment/Settings';
import { Helmet } from "react-helmet";
import './ResetPassword.css'

const ResetPassword = () => {
  const [metaTag, setMetaTag] = useState('');
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setMetaTag(Message.Description);
    setTitle(Message.Title);
    AuthService.Logout();
  }, []);

  const { email } = useParams();
  let navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: "all" });
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    UserService.ResetPassword(data).then(data => {

      setIsLoading(false);
      if (!data.isValidated) {
        toast.error(data.message, {
        });
      }
      else {
        toast.success(data.message, {
        });
      }
      makeRequest()
    });
  })
  async function makeRequest() {

    await delay(1000);
    navigate('/');
  }
  function redirecttohome() {
    window.location.href = Environment.PortalLink;
  }
  return (
    <div className='container-fluid container-middle-align login-page'>
      <Helmet>
        <title>Helm-Track</title>
        <meta name="title" content={title} />
        <meta name="description" content={metaTag} />
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-md-4 p-0 no-show">
          <div className='login-main-img'>
            <img className="img-fluid" src={LoginImg} />
          </div>
        </div>
        <div className="col-lg-8 card p-0 cardWidth">
          <div className="row login-data g-0">
            <div className="col-12">
              <div className="card-body h-100 d-flex justify-content-center align-items-center">
                <div className='full-width'>
                  <div className='text-center mb-4'>
                    <a onClick={redirecttohome} className="header-two-images cursor-pointer">
                      <img src={ImageUrl.Logo} alt="logo" className="img-fluid" />
                    </a>
                  </div>
                  <h5 className="card-title login-text mb-5">Reset Password</h5>
                  <ToastContainer closeOnClick={true} />
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12">
                        <input type='hidden' value={email} {...register('email', { required: true })} name='email' />
                      </div>
                    </div>
                    <div className='row centerAlign'>
                      <FormInputDiv col={"col-lg-12 col-md-12 col-12 mb-3"} register={register} inputClass={"effect-16 w-100"} name="password"
                        error={errors.password?.message} type={'Password'} requiredText={Message.RequiredField} requiredValid={Validation.ValidatePassword} ValidMessage={Message.ValidPassword}
                        placeholder={"Enter Password"}
                      />
                    </div>
                    <div className='row'>
                      <FormInputCp col={"col-lg-12 col-md-12 col-12"} register={register} inputClass={"effect-16 w-100"} name="cPassword"
                        error={errors.cPassword?.message} requiredText={Message.RequiredField} requiredValid={Validation.ValidatePassword} ValidMessage={Message.ValidPassword}
                        placeholder={"Re-enter the password"} validConfirmPassword={Message.ValidConfirmPassword} watch={watch}
                        type={'Password'}
                      />
                    </div>
                    <div className="row centerAlign mt-5 mb-5">
                      <div className="col-lg-12">
                        <div className='bottom-buttons d-flex justify-content-between align-items-center'>
                          <FormButton className={'btn btn-success'} IsLoading={isLoading} ButtonText="Reset" icon={true} />
                          <Link to="/">Back to login?</Link>
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;


