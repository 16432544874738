import { Method } from "../../Helpers/Method";
import { ApiUrl } from "../../Helpers/ApiUrl";
export const UserService = {
    ConfirmAccount, ForgotPassword, ResetPassword, SignUp, GetData, GetDataCompany, GoogleSignIn, ConfirmAccountEmail
};

function ConfirmAccount(email) {

    const res = Method.Post(ApiUrl.AccountConfirmation, email).then(data => {

        return data;
    });
    return res;
};
function ConfirmAccountEmail(email) {

    const res = Method.Post(ApiUrl.AccountConfirmationEmail, email).then(data => {

        return data;
    });
    return res;
};
function ForgotPassword(data) {
    const res = Method.Post(ApiUrl.ForgotPassword, data).then(data => {

        return data;
    });
    return res;
}

function ResetPassword(data) {
    const res = Method.Post(ApiUrl.ResetPassword, data).then(data => {

        return data;
    });
    return res;
}

function SignUp(data) {

    const res = Method.Post(ApiUrl.Signup, data).then(data => {

        return data;
    });
    return res;
}
function GoogleSignIn(data) {

    const res = Method.Post(ApiUrl.GoogleSignIn, data).then(data => {

        return data;
    });
    return res;
}

function GetDataCompany() {
    const res = Method.Get(ApiUrl.GetDataCompany).then(data => {

        return data;
    });
    return res;
}
function GetData() {

    const res = Method.Get(ApiUrl.GetData).then(data => {

        return data;
    });
    return res;
}
