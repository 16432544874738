import React, { useRef } from "react";
import { useScript } from "../../../../../../Helpers/SignInScript";
import jwt_decode from "jwt-decode";
import { Environment } from "../../../../../../Environment/Settings";
import { UserService } from "../../../../../../Services/User/UserService";
import { AuthService } from "../../../../../../Services/Auth/AuthService";
import { useNavigate, useLocation } from "react-router-dom";
import { AppUrl } from "../../../../../../Helpers/AppUrl";
export default function GoogleAuth() {
    let navigate = useNavigate();
    const googlebuttonref = useRef();
    const search = useLocation().search;
    const onGoogleSignIn = (user) => {

        let userCred = user.credential;
        let payload = jwt_decode(userCred);
        UserService.GoogleSignIn({
            isGmail: true, email: payload.email, emailConfirmed: payload.email_verified, imageUrl: payload.picture,
            lastName: payload.family_name, firstName: payload.given_name, userName: "", password: 'Admin@123', confirmPassword: 'Admin@123'
        }).then(data => {

            AuthService.Cookies(data);
            var url = new URLSearchParams(search).get("returnurl");
            if (url !== "" && url !== undefined && url !== null) {
                var path = url.split("/", 2);
                if (path[1] === AppUrl.Vessel || path[1] === AppUrl.Company || path[1] === AppUrl.AudinenceBuilder || path[1] === AppUrl.CompanyDetailForLogin) {

                    navigate(url);
                }
                else if (path[0] === AppUrl.VesselDetail) {
                    navigate("/" + url);
                }
                else if (path[1] === AppUrl.Propulsion || path[1] === AppUrl.PropulsionDetail || path[1] === AppUrl.MartimeNews
                    || path[1] === AppUrl.ContactUsLogin || path[1] === AppUrl.Privacy || path[1] === AppUrl.Cookiepolicy || path[1] === AppUrl.Terms
                ) {
                    window.location.href = Environment.PortalLink + url;
                }
                else {
                    window.location.href = Environment.PortalLink;
                }
            }
            else {
                window.location.href = Environment.PortalLink;
            }
        });
    };


    //Review zaki: Get the url from .env.dev /prod(Done)
    useScript(Environment.GoogleUrl, () => {

        window.google.accounts.id.initialize({
            client_id: Environment.ClientId, // here's your Google ID
            callback: onGoogleSignIn,
            auto_select: false,
        });

        window.google.accounts.id.renderButton(googlebuttonref.current, {
            size: "medium",
        });
    });
    return (
        <div className="d-flex h-25">
            <button className="btn border-0 p-0" ref={googlebuttonref}></button>
        </div>
    );
};