import { Method } from "../../Helpers/Method";
import { ApiUrl } from "../../Helpers/ApiUrl";
import Cookie from "universal-cookie";
import { Environment } from "../../Environment/Settings";
const cookie = new Cookie();
export const ProfileService = {
    UpdatePassword, GetProfile, UpdateProfile//, UpdateProfileWithOutImage
};

function UpdatePassword(data) {
    const res = Method.Post(ApiUrl.UpdatePassword, data).then(data => {

        return data
    });
    return res;;
}

function GetProfile() {

    const res = Method.Get(ApiUrl.GetProfile).then(data => {

        return data
    });
    return res;
}

function UpdateProfile(data) {
    const res = Method.PostFormData(ApiUrl.UpdateProfile, data).then(data => {

        if (data.isValidated) {
            const options = { path: "/", domain: Environment.Sub_Domain };
            cookie.set("current_user", JSON.stringify(data.data), options);
        }
        return data
    });
    return res;

}