import React from 'react'
import { Environment } from '../../../Environment/Settings';

const Maritime = () => {
    window.location.href = Environment.NewsPage;
    return (
        <div></div>
    )
}

export default Maritime